export const clinics = {
    kyiv: {
        city: 'м.Київ',
        str: 'вул.Макаренка 1а',
        location: 'м.Київ, вул.Макаренка 1а',
        phones: ['(098) 507 19 88', '(093) 853 29 07'],
        coordinate: { lat: 50.44771, lng: 30.6731118 },
    },
    brovary: {
        city: 'м.Бровари',
        str: 'вул.Київська 161',
        phones: ['(098) 961 02 62'],
        location: 'м.Бровари, вул.Київська 161 БЦ"Нива" 2й поверх',
        coordinate: { lat: 50.50062265971192, lng: 30.772361416440337 },
    },
}
