import exampleImg1 from 'images/examples/example1.jpg'
import exampleImg2 from 'images/examples/example2.jpg'
import exampleImg3 from 'images/examples/example3.jpg'
import exampleImg4 from 'images/examples/example4.jpg'
import exampleImg5 from 'images/examples/example5.jpg'

const examplesInfo = [
    {
        id: 1,
        img: exampleImg1,
        title: 'Протезування',
        description: 'Протезування передніх зубів',
    },
    {
        id: 2,
        img: exampleImg2,
        title: 'Імплантація',
        description: 'Імплантація з кістною аугментацією',
    },
    {
        id: 3,
        img: exampleImg3,
        title: 'Виправлення прикусу',
        description: 'Виправлення прикусу',
    },
    {
        id: 4,
        img: exampleImg4,
        title: 'Гігієна',
        description: 'Професійна гігієна ротової порожнини',
    },
    {
        id: 5,
        img: exampleImg5,
        title: 'Відбілювання',
        description: 'Проведена процедура офісного відбілювання',
    },
]

export default examplesInfo
