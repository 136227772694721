import React from 'react'

const WorkingHours = () => {
    return (
        <>
            <p>
                <span>Пн-Пт:</span> 09:00 - 19:00
            </p>
            <p>
                <span>Сб-Нд:</span> 09:00 - 18:00
            </p>
        </>
    )
}

export default WorkingHours
