import { action, computed, makeObservable, observable } from 'mobx'

class Store {
    isFormSent = false
    location = 'kyiv'
    constructor() {
        makeObservable(this, {
            isFormSent: observable,
            location: observable,
            getUserLocation: computed,
            setUserLocation: action,
            getFormStatus: computed,
            setFormStatusToSuccess: action,
        })
    }

    get getUserLocation() {
        return this.location
    }

    setUserLocation(location) {
        return (this.location = location)
    }

    get getFormStatus() {
        return this.isFormSent
    }

    setFormStatusToSuccess() {
        this.isFormSent = true
    }
}

const store = new Store()

export default store
