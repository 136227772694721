import React from 'react'
import WorkingHours from 'components/common/working-hours'
import arrow from 'assets/arrow.svg'
import FullMap from './map'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

import { inject, observer } from 'mobx-react'
import { clinics } from 'shared/constants'
import { hrefPhone } from 'shared/utils/phone'

const AccordionBody = ({ location }) => {
    return (
        <div className="row">
            <div className="col contacts-details">
                <WorkingHours />
            </div>
            <div className="col contacts-details">
                {clinics[location]?.phones &&
                    clinics[location].phones.map((phone) => (
                        <div key={phone}>
                            <a href={hrefPhone(phone)}>+38 {phone}</a>
                        </div>
                    ))}
            </div>
        </div>
    )
}

const HomeContacts = ({ store }) => {
    const { location } = store

    const setMainLocation = (city) => {
        store.setUserLocation(city)
    }
    return (
        <div className="contacts">
            <div className="container">
                <div className="contacts-wrapper">
                    <h2 className="title">Наші контакти</h2>
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <Accordion
                                activeKey={location === 'kyiv' ? '0' : '1'}
                            >
                                <Card>
                                    <Accordion.Item
                                        as={Card.Header}
                                        eventKey="0"
                                        onClick={() => setMainLocation('kyiv')}
                                    >
                                        <div className="row align-items-center">
                                            <div className="col-10">
                                                <p className="city">Київ</p>
                                                <p className="street">
                                                    {clinics.kyiv.str}
                                                </p>
                                            </div>
                                            <div className="col-2">
                                                <div
                                                    className={
                                                        location === 'kyiv'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    <img
                                                        src={arrow}
                                                        alt="arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Item>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <AccordionBody
                                                location={location}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Item
                                        as={Card.Header}
                                        eventKey="1"
                                        onClick={() =>
                                            setMainLocation('brovary')
                                        }
                                    >
                                        <div className="row align-items-center">
                                            <div className="col-10">
                                                <p className="city">Бровари</p>
                                                <p className="street">
                                                    {clinics.brovary.str}
                                                </p>
                                            </div>
                                            <div className="col-2">
                                                <div
                                                    className={
                                                        location === 'brovary'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    <img
                                                        src={arrow}
                                                        alt="arrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Item>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <AccordionBody
                                                location={location}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <div className="col-lg-8 col-12">
                            <FullMap location={clinics[location].coordinate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default inject('store')(observer(HomeContacts))
