import React from 'react'
import { inject, observer } from 'mobx-react'
import emailjs from '@emailjs/browser'

// after audit
// TODO: check props not sure is this props are used.
const FormComponent = ({ store }) => {
    // const { errors, touched, values } = props;
    // const phoneNumberMask = [
    //     "(",
    //     /[0-9]/,
    //     /\d/,
    //     /\d/,
    //     ")",
    //     " ",
    //     /\d/,
    //     /\d/,
    //     /\d/,
    //     "-",
    //     /\d/,
    //     /\d/,
    //     "-",
    //     /\d/,
    //     /\d/,
    // ];

    const sendEmail = (e) => {
        e.preventDefault()
        const userId = 'user_hH1eEPn98s2KHF7vfzOEy'

        // TODO: apikey to move to env
        emailjs.sendForm(userId, 'solodent_template', e.target, userId).then(
            function (response) {
                store.setFormStatusToSuccess()
                console.log('SUCCESS!', response.status, response.text)
            },
            function (err) {
                console.log('FAILED...', err)
            }
        )
    }

    return (
        <>
            <form className="contact-form" onSubmit={sendEmail}>
                <select
                    className="form-control ds-input"
                    name="location"
                    required
                >
                    <option value="">Оберіть місто</option>
                    <option value="kyiv">Київ</option>
                    <option value="brovary">Бровари</option>
                </select>
                <input
                    className="form-control ds-input"
                    type="text"
                    name="username"
                    placeholder="Ваше ім'я"
                    required
                />
                <input
                    className="form-control ds-input"
                    type="text"
                    name="phone"
                    placeholder="Номер телефону"
                    required
                />
                <button type="submit" className="btn">
                    Записатись на прийом
                </button>
            </form>
        </>
    )
}

export default inject('store')(observer(FormComponent))
