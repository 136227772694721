import React, { useState } from 'react'
import {
    GoogleMap,
    Marker,
    LoadScript,
    InfoWindow,
} from '@react-google-maps/api'
import mapMarker from 'assets/logo-ggl.svg'

const Map = ({ location, isOpen, markerOnCLick }) => {
    const icon = {
        url: mapMarker,
        scale: 0.5,
    }
    return (
        <LoadScript googleMapsApiKey="AIzaSyAiDUPzu0qPEkNCeOmxSXm2kbZqgqTX9J4">
            <GoogleMap
                mapContainerStyle={{ height: `400px` }}
                center={location}
                zoom={17}
            >
                <Marker
                    title={'Solodent'}
                    position={location}
                    onClick={markerOnCLick}
                    icon={icon}
                >
                    {isOpen && (
                        <InfoWindow>
                            <div className="info-window">
                                <div className="title">
                                    Стоматологічна клініка Solodent
                                </div>
                                <p>
                                    <i
                                        className="fa fa-clock-o"
                                        aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp; пн-птн: 09:00 - 21:00
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;сб-нд:
                                    09:00 - 15:00
                                </p>
                                <p>
                                    <i
                                        className="fa fa-phone"
                                        aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;
                                    <a href="tel:+38098-507-19-88">
                                        +38 (098) 507 19 88
                                    </a>
                                </p>
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            </GoogleMap>
        </LoadScript>
    )
}

const WrappedMap = React.memo(Map)

const FullMap = ({ location }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <WrappedMap
            isOpen={isOpen}
            markerOnCLick={() => setIsOpen(!isOpen)}
            location={location}
        />
    )
}
export default FullMap
