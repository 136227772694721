import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { inject, observer } from 'mobx-react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import OurTeamItem from './our-team-item'
import ourTeamInfo from './our-team-info'
import longArrow from '../../../../assets/long-arrow.svg'

const OurTeamList = ({ store }) => {
    const [currentSlide, setCurrentSlide] = useState(1)
    const settings = {
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        afterChange: (index) => setCurrentSlide(index + 1),
    }

    const team = ourTeamInfo.filter((el) =>
        el.locationId.includes(store.location)
    )

    return (
        <div className="our-team">
            <select
                name="city"
                className="form-control ds-input"
                onChange={(e) => store.setUserLocation(e.target.value)}
                value={store.location}
            >
                <option value="kyiv">Київ</option>
                <option value="brovary">Бровари</option>
            </select>

            <Slider {...settings}>
                {team.map(
                    ({
                        id,
                        name,
                        location,
                        qualification,
                        description,
                        img,
                    }) => {
                        return (
                            <OurTeamItem
                                key={id}
                                img={img}
                                name={name}
                                location={location}
                                qualification={qualification}
                                description={description}
                            />
                        )
                    }
                )}
            </Slider>
            <Link to="/about/#our-team" className="our-team__all-doctors">
                Всі лікарі
                <img src={longArrow} alt="arrow-right" />
            </Link>
            <div className="counter">
                <span>{currentSlide}</span> з {team.length}
            </div>
        </div>
    )
}

export default inject('store')(observer(OurTeamList))

// slidesToShow: 3,
// autoplay: true,
// autoplaySpeed: 0,
// speed: 5000,
// cssEase:'linear',
// infinite: true,
// focusOnSelect: false,
// pauseOnFocus: false,
// pauseOnHover: false,
// touchMove: false,
// centerMode: true,
// variableWidth: true,
