import React, { useMemo, useState } from 'react'
import priceData from './price-data.json'

const PricesTable = () => {
    const [selectedTitle, setTitle] = useState(priceData[0].title)
    const selectedList = useMemo(() => {
        const list = priceData.find((item) => item.title === selectedTitle)
        return list || []
    }, [selectedTitle])

    return (
        <div className="prices-table">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <ul className="titles">
                            {priceData.map((item) => (
                                <li
                                    className={
                                        selectedTitle === item.title
                                            ? 'active'
                                            : ''
                                    }
                                    key={item.title}
                                    onClick={() => setTitle(item.title)}
                                >
                                    {item.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-8">
                        <ul className="subtitles">
                            {selectedList.subtitle.map(
                                ({ title, price }, i) => (
                                    <div key={`${title}-${i}`} className="row">
                                        <div className="col-9">
                                            <li>{title}</li>
                                        </div>
                                        <div className="col-3">
                                            <li>{price}</li>
                                        </div>
                                    </div>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricesTable
