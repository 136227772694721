import React from 'react'
import { inject, observer } from 'mobx-react'
import { clinics } from 'shared/constants'

const ClientLocation = ({ store }) => {
    const { location } = store

    const handleChange = (e) => {
        store.setUserLocation(e.target.value)
    }

    return (
        <div className="client-location d-flex justify-content-end align-items-center">
            <span className="text-sm">Наша адреса</span>
            <select
                style={{ width: location === 'kyiv' ? 40 : 70 }}
                className="custom-select pointer"
                onChange={handleChange}
                value={location}
            >
                {Object.entries(clinics).map(([key, value]) => (
                    <option key={key} value={key}>
                        {value.city}
                    </option>
                ))}
            </select>
            <span className="location-street">{clinics[location].str}</span>
        </div>
    )
}

export default inject('store')(observer(ClientLocation))
