import React from 'react'
import { Link } from 'react-router-dom'
import logo from './../../assets/logo.svg'
import { clinics } from 'shared/constants'
import { hrefPhone } from 'shared/utils/phone'

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer-wrapper">
                    <div className="row">
                        <div className="col-lg-4">
                            <p>ПОСЛУГИ</p>
                            <ul>
                                <li className="row">
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Терапія
                                    </Link>
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Дитяча стоматологія
                                    </Link>
                                </li>
                                <li className="row">
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Терапевтичне лікування
                                    </Link>
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Протезування
                                    </Link>
                                </li>
                                <li className="row">
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Відбілювання
                                    </Link>
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Ортодонтичний прийом
                                    </Link>
                                </li>
                                <li className="row">
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Хірургія
                                    </Link>
                                    <Link
                                        to="/services"
                                        className="col-6 text-sm"
                                    >
                                        Реставрація
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-about">
                                <p>ПРО КЛІНІКУ</p>
                                <ul>
                                    <li>
                                        <Link
                                            to="/about"
                                            className="navbar-link text-sm"
                                        >
                                            Про нас
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/prices"
                                            className="navbar-link text-sm"
                                        >
                                            Ціни
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <p>КОНТАКТИ</p>
                            <ul>
                                <li className="text-sm">
                                    <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                    ></i>
                                    {clinics.kyiv.location}
                                </li>
                                {clinics.kyiv.phones.map((phone) => (
                                    <li className="text-sm" key={phone}>
                                        <i
                                            className="fa fa-phone"
                                            aria-hidden="true"
                                        ></i>
                                        <a
                                            href={hrefPhone(phone)}
                                            className="phone text-sm"
                                        >
                                            +38 {phone}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                <li className="text-sm">
                                    <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                    ></i>
                                    {clinics.brovary.location}
                                </li>
                                {clinics.brovary.phones.map((phone) => (
                                    <li className="text-sm" key={phone}>
                                        <i
                                            className="fa fa-phone"
                                            aria-hidden="true"
                                        ></i>
                                        <a
                                            href={hrefPhone(phone)}
                                            className="phone text-sm"
                                        >
                                            +38 {phone}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <p className="copyright text-sm">
                    © {new Date().getFullYear()} | Всі права захищені Солодент
                </p>
            </div>
        </div>
    )
}

export default Footer
